<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-icon>fas fa-filter</v-icon>
                    <v-toolbar-title>{{ this.title }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <!--#region Theme  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t(val.EFResolutionText.label) }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.EFResolutionText.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.EFResolutionText.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region RegNumber  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.RegNumber.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="val.RegNumber.value"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.RegNumber.value = null"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region RegDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateFrom.value"
                                        @input="regDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region RegDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateTo.value"
                                        @input="regDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                    <!--#endregion -->

                    <!--#region ResolutionAuthor  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.ResolutionAuthor.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <v-select
                                return-object
                                :items="itemsResolutionAuthor"
                                v-model="val.ResolutionAuthor.value"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                multiple
                                @click:clear="val.ResolutionAuthor.value = []"
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-text-field 
                                                :placeholder="$t('Поиск')"
                                                class="mb-2 autocomplete-search"
                                                hide-details
                                                dense
                                                clearable
                                                @input="searchResolutionAuthor"
                                                autofocus
                                            >
                                            </v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>

                        </v-col>
                    </v-row>
                    <!--#endregion -->

                    <!--#region ExecuterWorkStatus  -->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t(val.ExecuterWorkStatus.label)}}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">

                            <v-select
                                return-object
                                :items="executerWorkStatuses"
                                v-model="val.ExecuterWorkStatus.value"
                                :item-text="item => $refLocale(item, $i18n.locale)"
                                item-value="id"
                                hide-details
                                required
                                outlined
                                dense
                                clearable
                                @click:clear="val.ExecuterWorkStatus.value = null"
                                append-icon="fas fa-chevron-down"
                                :menu-props="{ bottom: true, offsetY: true }"
                            ></v-select>

                        </v-col>
                    </v-row>
                    <!--#endregion -->
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                        v-if="isFormValid"
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import sys from '@/services/system';

export default {
    name: "CorrespondenceInnerActItemsExFilterDlg",
    props:
    {
        value:
        {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),

            regDateFromMenu: false,
            regDateToMenu: false,

            itemsResolutionAuthor: [],
            executerWorkStatuses: [
                {
                    id: 'Закрыто',
                    Value: this.$t('Исполнено')
                },
                {
                    id: 'В_работе',
                    Value: this.$t('На_исполнении')
                },
                {
                    id: 'Дан_ответ',
                    Value: this.$t('На_проверке_исполнения')
                },
                {
                    id: 'Отправлено_на_доработку',
                    Value: this.$t('Доработать')
                },
                {
                    id: 'Просрочено',
                    Value: this.$t('Просрочено')
                }
            ],
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    methods: {
        async initialize() {
            this.itemsResolutionAuthor = (await this.getWorkplaces()).InnerExecuters.Executers
                .map(i =>                 
                ({
                    id: i[0],
                    Value: i[1],
                    employeeId: i[2],
                    jobTitle: i[3]
                }))
                .sort(function (a, b) {
                    return ('' + a.Value).localeCompare(b.Value);
                });
        },
        ...mapActions('documents/references', ['getReference', 'getWorkplaces']),
        ...mapActions(['setOverlayVisible']),
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);

            this.setOverlayVisible({ visible: true });
            this.initialize();
            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);
            this.resolve();
        },
        handleDate(source) {
            var formatedDate = sys.dateFormat(source, 'DD.MM.YYYY');
            return formatedDate ?? "";
        },
        async searchResolutionAuthor(e) {
            if (e) {
                let selectedIds = this.val.ResolutionAuthor.value.length ? this.val.ResolutionAuthor.value.map(i => i.id): [];                    
                this.itemsResolutionAuthor = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]})).filter( i => sys.checkSearch(i.Value, e) || selectedIds.includes(i.id));
            }
            else
                this.itemsResolutionAuthor = (await this.getWorkplaces()).InnerExecuters.Executers.map(i => ({id: i[0], Value: i[1]}));
        },
    }
}
</script>