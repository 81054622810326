<template>
    <div class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="true"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="status"
        >
            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />
        </FilterPanel>

        <v-row no-gutters>

            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="entities"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.task_id, type: 'Documents|Orders|CitizenStatements.ResolutionTask' })">

                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>
                    
                    <template v-slot:[`item.regnumber`]="{ item }">
                        <v-document-link
                            :id="item.doc_id"
                            :type="'Chancellery|Documents.Document'"
                            :text="item.regnumber"
                        />
                    </template>

                    <template v-slot:[`item.regdate`]="{ item }">
                        <div>{{ dateFormat(item.regdate, 'DD.MM.YYYY') }}</div>
                    </template>

                    <template v-slot:[`item.resolutiontext`]="{ item }">
                        <div class="elipsis-text-wrap">                        
                            <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.resolutiontext">
                                    {{ item.resolutiontext }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.currentlimit`]="{ item }">
                        <div>{{ dateFormat(item.currentlimit, 'DD.MM.YYYY') }}</div>
                    </template>

                    <template v-slot:[`item.executername`]="{ item }">
                        <v-workplace-chip v-if="item.executerid" :id="item.executerid" :name="item.executername" />
                        <v-enterprise-chip v-else :name="item.executername" />
                    </template>

                    <template v-slot:[`item.workstatus`]="{ item }">
                        <StatusChip :status="availableStatuses[mapAvroraStatus(item.workstatus)]"/>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>

        <!-- модалка -->
        <InnerActItemsExFilterDlg
            v-model="extendedTableFilter"
            ref="ExFilterDlgRef"
        />

    </div>
</template>

<script>
import _ from 'lodash';
import sys from "@/services/system";
import adapter from "@/services/adapter";
import { mapGetters, mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';
import InnerActItemsExFilterDlg from '@/components/documents/dialogs/extended-filters/InnerActItems.vue';

export default {
    columnIndex:  {
        'regnumber': 3,
        'regdate': 4,
        'resolutiontext': 5,
        'currentlimit': 6,
        'executername': 7
    },
    name: "CorrespondenceInnerActItems",
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,
        ExtendedFilterPanel,
        StatusChip,
        InnerActItemsExFilterDlg
    },
    computed : {
        ...mapGetters('documents/references',  { availableStatuses: 'GetAvaibleStatuses' }),
        headers() {
            return [
                { text: this.$t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: this.$t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: this.$t("Краткое_содержание"), value: 'resolutiontext'},
                { text: this.$t("Срок_исполнения"), value: 'currentlimit', width: '150px' },
                { text: this.$t("Исполнитель"), value: 'executername', width: '15%' },
                { text: this.$t("Статус"), value: 'workstatus', width: '150px', sortable: false }
            ];
        },
        status: {
            get: function() {
                return this.$store.getters['correspondence/innerActItems/getStatus'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('correspondence/innerActItems/SET_STATUS', newValue);
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['correspondence/innerActItems/getSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('correspondence/innerActItems/SET_SEARCH', v);
            }, 500)
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['correspondence/innerActItems/getExtendedFilter'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('correspondence/innerActItems/SET_EXTENDED_FILTER', newValue);
                }
            }
        },
        options: {
            get: function() {
                return this.$store.getters['correspondence/innerActItems/getTableOptions'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('correspondence/innerActItems/SET_TABLE_OPTION', newValue);
                }
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                ResolutionType: this.status,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
    },
    data() {
        return {
            collection: "InnerActItems",
            filterDataSource: null,
            urls: [],

            loading: false,
            total: 0,
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,

            expanded: [],
            open: {},
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        dateFormat: sys.dateFormat,
        getDaysDiff: sys.getDaysDiff,
        executerStatusColor: sys.getExecuterStatusColor,
        itemRowBackground: (item) => item.readed === true ? '' : 'font-weight-bold',
        getLabel: (item) => item.Values.toString(),
        onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        async Refresh() {
            await this.loadCollectionData();
            await this.getData(true);
        },
        async Export() {
            let filter = this.getTableFilterObject();
            let exportURI = `api/download/export?param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;

            delete filter.Limit;
            delete filter.Offset;

            if (process.env.NODE_ENV === "production")
                exportURI += `&token=${this.$store.getters['auth/getUserInfo']?.Token}`;

            this.$eventBus.$emit('on-get-report', { url: exportURI, text: `Экспорт списка пунктов ОРД от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'xls' });
        },
        async onFilterClick() {
            try
            {
                await this.$refs.ExFilterDlgRef.open();
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async loadCollectionData() {
            this.setOverlayVisible({ visible: true });

            try
            {
                let response = await httpAPI({
                    url: `api/actions/collection?type=Documents.Correspondence`,
                    method: 'GET'
                });

                if (response) {
                    await this.loadFilters();
                    this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
                }
            }
            finally
            {
                this.setOverlayVisible({ visible: false });
            }
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `api/correspondence/filter?collection=${this.collection}&configuration=8`,
                method: 'GET'
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['global/auth/getUserInfo'])?.Permissions ?? [];
                var filter = filterResponse.data.Payload.Filter.Items.find(x => x.Value == this.collection);

                if (filter && filter.Items) {
                    filter.Items.Items = filter.Items.Items?.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                    this.filterDataSource = { Filter: filter.Items };
                }
            }
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ResolutionType, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "currentlimit" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 6;

            return { 
                SearchStr,
                ResolutionType,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
                ...extendedFilterData
            };
        },
        async getData(isRefresh = false) {
            this.$eventBus.$emit('update_correspondents_counters');
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            
            let response = await httpAPI({
                url: `api/correspondence/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET'
            });
            
            this.open = {};
            this.expanded = [];

            this.entities = response.data?.Payload?.data ?? [];
            this.total = response.data?.Payload?.total ?? 0;

            this.loading = false;      
        },
        async oneStatusColWidth() {
            var parent = document.getElementsByClassName('content-box')[0];
            var status_col_obj = parent.getElementsByClassName('status-col');
            var status_dropdown_col_obj = parent.getElementsByClassName('status-dropdown-col');
            var status_col_w = status_col_obj.length > 0 ? status_col_obj[0].clientWidth : 0;

            if (status_dropdown_col_obj.length > 0) {
                for (var index = 0; index < status_dropdown_col_obj.length; index++) {
                    status_dropdown_col_obj[index].style.width = status_col_w + "px";
                }
            }
        },
        async itemExpanded(val) {
            if (!val.value) return;
            
            this.$set(val.item, '_info', null)

            let response = await httpAPI({
                url: `api/correspondence/getdocumentchildren?id=${val.item.id}`,
                method: 'GET',
                headers: { 'isCommon': val.item.iscommon }
            });
            
            let data = response?.data?.Payload?.Data?.Object?.data;
            
            if (data)
            {
                let treeData = adapter.transformTreeData(null, data, val.item.id);
                this.$set(val.item, '_info', treeData)
            }   
        },
        mapAvroraStatus(avroraStatus) {
            switch (avroraStatus)
            {
                case 'Закрыто': return 'executed';
                case 'Просрочено': return 'expired';
                case 'Отправлено_на_доработку': return 'needRework';
                case 'Дан_ответ': return 'onExecutionCheck';
                case 'Новое':
                case 'В_работе': return 'onExecution';
                default: return 'unknown';
            }
        }
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
        open: {
            handler() {
                this.$nextTick(this.oneStatusColWidth);
            },
            deep: true,
        }
    },
    mounted() {
        this.$nextTick(this.oneStatusColWidth);
        window.addEventListener('resize', this.oneStatusColWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.oneStatusColWidth);
        this.urls.forEach(url => URL.revokeObjectURL(url));
    },
    beforeRouteEnter(to, from, next) {
        next(async vm =>
        {
            await vm.loadCollectionData();
        });
    },
    async beforeRouteUpdate(to, from, next) {
        await this.loadCollectionData();
        next();
    },
}
</script>